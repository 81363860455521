import axiosAPI from '@/axiosAPI'
import SearchContext from '@/constants/SearchContext'
import SearchContextOption from '@/constants/SearchContextOption'
import SearchStore from './store/SearchStore'
import ShopBasketStore from '@/store/ShopBasketStore'
import ShopLibraryStore from '@/store/ShopLibraryStore'
import SettingsStore from '@/store/SettingsStore'
import BookshelfStore from '@/store/BookshelfStore'
import Collection from '@/utilities/collection'
import VueCookies from 'vue-cookies'
import { localStorageLanguageKey, defaultClientLanguage } from '@/constants/defaults'
import { roleIncludes } from '@/utilities/roleHelper'
import Roles from '@/constants/roles'

import { createStore } from 'vuex'

const getEnvironmentLabel = () => {
  if (!window.globals.VUE_APP_ENVIRONMENTLABEL) return null

  const label = window.globals.VUE_APP_ENVIRONMENTLABEL.toUpperCase()
  if (label === 'PRODUCTION') return null

  return label
}

export default createStore({
  modules: {
    shop: {
      namespaced: true,
      modules: {
        library: ShopLibraryStore(),
        basket: ShopBasketStore()
      }
    },
    searchStore: SearchStore(),
    settings: SettingsStore(),
    bookshelfStore: BookshelfStore()
  },
  state: {
    // eslint-disable-next-line no-undef
    appVersion: __VERSION__,
    initialised: false,
    publicLibrary: false,
    environmentLabel: getEnvironmentLabel(),
    auth: {
      accessToken: null,
      refreshToken: null
    },
    session: {},
    client: {},
    role: null,
    language: defaultClientLanguage,
    languages: [],
    search: {
      query: '',
      context: SearchContext.METADATA,
      contextOption: SearchContextOption.WORDS,
      page: -1,
      results: [],
      facets: [],
      activeFacets: {},
      noMoreResults: true,
      resultcount: 0,
      facetSettings: VueCookies.isKey('facetSortOrder') ? VueCookies.get('facetSortOrder') : {}
    },
    view: {
      selectedIndex: '',
      showFacets: false
    },
    loadingInitial: false,
    loadingPage: false,
    loadingError: false,
    FACET_SORT_ORDER_ASCENDING: 1,
    FACET_SORT_ORDER_DESCENDING: 2,
    FACET_SORT_TYPE_VALUES: 1,
    FACET_SORT_TYPE_HITS: 2
  },
  getters: {
    getAppVersion (state) {
      return state.appVersion
    },
    getCatalogueResults (state) {
      return state.search.results
    },
    getCatalogueFacets (state) {
      return state.search.facets
    },
    getCatalogueFacetsCollection (state) {
      return new Collection(state.search.facets)
    },
    getActiveSearchFacets (state) {
      return state.search.activeSearch
    },
    getResultCount (state) {
      return state.search.resultcount
    },
    getActiveSearchFacetsForGroup: (state) => (facetgroup) => {
      const activeFacets = state.search.activeFacets[facetgroup]
      if (!activeFacets) return []
      return activeFacets
    },
    getAuth (state) {
      return state.auth
    },
    getClient (state) {
      return state.client
    },
    getCurrentSearch (state) {
      return state.search
    },
    getSearchContext (state) {
      return state.search.context
    },
    getSearchContextOption (state) {
      return state.search.contextOption
    },
    getCurrentView (state) {
      return state.view
    },
    getSelectedIndex (state) {
      return state.view.selectedIndex
    },
    getFacetSettings (state) {
      return state.search.facetSettings
    },
    getLanguages (state) {
      return state.languages
    },
    getSession (state) {
      return state.session
    },
    getRole (state) {
      return state.role
    },
    userHasAnonymousRole (state) {
      return roleIncludes(state.role, Roles.ANONYMOUS)
    },
    userHasUserRole (state) {
      return roleIncludes(state.role, Roles.USER)
    },
    userHasAdminRole (state) {
      return roleIncludes(state.role, Roles.ADMIN)
    },
    userHasSuperadminRole (state) {
      return roleIncludes(state.role, Roles.SUPERADMIN)
    },
    isPublicLibrary (state) {
      return state.publicLibrary
    }
  },
  mutations: {
    setCatalogueResults (state, results) {
      state.search.results = results
    },
    addCatalogueResults (state, results) {
      Array.prototype.push.apply(state.search.results, results)
    },
    setCatalogueFacets (state, facets) {
      const filteredFacets = []

      Object.keys(facets).forEach(facetgrouplabel => {
        const facetgroup = facets[facetgrouplabel]
        const facetgroupContentLabels = Object.keys(facetgroup)
        let facetgroupContentCounter = 0
        const filteredFacetGroupContent = []

        facetgroupContentLabels.forEach(facetLabel => {
          const count = facetgroup[facetLabel]

          if (count === 0) return

          filteredFacetGroupContent.push([facetLabel, count])

          facetgroupContentCounter++
        })

        if (facetgroupContentCounter > 0) filteredFacets.push([facetgrouplabel, filteredFacetGroupContent])
      })

      state.search.facets = filteredFacets
    },
    addSearchFacet (state, facet) {
      const facetGroup = Object.keys(facet)[0]
      if (!facetGroup) return
      const facetKey = facet[facetGroup]
      if (!facetKey || facetKey === '') return

      if (!state.search.activeFacets[facetGroup]) {
        state.search.activeFacets[facetGroup] = []
      } else {
        if (state.search.activeFacets[facetGroup].indexOf(facetKey) !== -1) return
      }

      state.search.activeFacets[facetGroup].push(facetKey)
    },
    removeSearchFacet (state, facet) {
      const facetGroup = Object.keys(facet)[0]
      if (!facetGroup || !state.search.activeFacets[facetGroup]) return
      const facetKey = facet[facetGroup]
      if (!facetKey || facetKey === '') return

      const activeFacetArray = state.search.activeFacets[facetGroup]
      const facetIndex = activeFacetArray.indexOf(facetKey)
      if (facetIndex === -1) return

      activeFacetArray.splice(facetIndex, 1)
      state.search.activeFacets[facetGroup] = activeFacetArray
      if (!activeFacetArray.length) delete state.search.activeFacets[facetGroup]
    },
    clearSearchFacets (state) {
      state.search.activeFacets = {}
    },
    setQuery (state, query) {
      state.search.query = query
    },
    setPage (state, page) {
      state.search.page = page
    },
    setNoMoreResults (state, noMoreResults) {
      state.search.noMoreResults = noMoreResults
    },
    setSearchContext (state, context) {
      if (context === SearchContext.METADATA || context === SearchContext.FULL_TEXT || context === SearchContext.ALL) {
        state.search.context = context
      }
    },
    setSearchContextOption (state, contextOption) {
      if (contextOption === SearchContextOption.WORDS || contextOption === SearchContextOption.PHRASE) {
        state.search.contextOption = contextOption
      }
    },
    setResultCount (state, count) {
      if (!count && count !== 0) count = 0
      state.search.resultcount = count
    },
    setSelectedIndex (state, index) {
      state.view.selectedIndex = index
    },
    setViewFacetsShowState (state, show) {
      state.view.showFacets = show
    },
    setLoadingInitialState (state, status) {
      state.loadingInitial = status
    },
    setLoadingPageState (state, status) {
      state.loadingPage = status
    },
    setLoadingErrorState (state, status) {
      state.loadingError = status
    },
    setFacetSettings (state, settings = {}) {
      state.search.facetSettings = settings
    },
    setLanguage (state, language) {
      state.language = language
    },
    setLanguages (state, languages = []) {
      state.languages = languages
    },
    setClient (state, client = {}) {
      state.client = client
    },
    setSession (state, session = {}) {
      state.session = session
    },
    setRole (state, role = null) {
      state.role = role
    },
    setPublicLibrary (state, isPublicLibrary) {
      state.publicLibrary = isPublicLibrary
    }
  },
  actions: {
    async initialise ({ commit, dispatch, state }) {
      try {
        const { data: clientResponse } = await axiosAPI.get('/client')
        commit('setClient', clientResponse)

        const { data: sessionResponse } = await axiosAPI.get('/user/me')
        commit('setSession', sessionResponse)

        const { data: roleResponse } = await axiosAPI.get('/user/role')
        commit('setRole', roleResponse)

        if (roleResponse === null) {
          // Go back to the sso service when the user does not have a role
          window.location = `${window.globals.VUE_APP_SSOURL}/application/${window.globals.VUE_APP_SSOAPPID}`
        }

        await dispatch('settings/fetchClientSettings')
        await dispatch('searchStore/searchModules/initialise')
        await dispatch('bookshelfStore/initialise')
      } catch (e) {
        throw Error('Could not initialise Hybrid Library')
      }
    },
    APICatalogueSearch ({ commit, state }, options = {}) {
      return new Promise((resolve, reject) => {
        if (!options.query) options.query = ''
        if (!options.page || options.page < 1) options.page = 1
        if (typeof options.replaceResults === 'undefined') options.replaceResults = true
        if (typeof options.replaceFacets === 'undefined') options.replaceFacets = true
        if (typeof options.resetActiveFacets === 'undefined') options.resetActiveFacets = true
        if (typeof options.resetSelectedIndex === 'undefined') options.resetSelectedIndex = true

        if (options.resetActiveFacets) commit('clearSearchFacets')
        if (options.resetSelectedIndex) commit('setSelectedIndex', '')

        commit('setQuery', options.query)
        commit('setPage', options.page)

        axiosAPI.get('/publications', {
          params: {
            query: options.query,
            context: state.search.context,
            contextOption: state.search.contextOption,
            page: options.page,
            filters: state.search.activeFacets
          }
        })
          .then(({ data }) => {
            if (options.replaceResults) {
              commit('setCatalogueResults', data.publications)
            } else {
              commit('addCatalogueResults', data.publications)
            }

            if (options.replaceFacets) {
              commit('setCatalogueFacets', data.facets)
            }

            commit('setResultCount', data.total)

            commit('setNoMoreResults', (!data.publications.length))

            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    APICatalogueSearchNew ({ dispatch }, query) {
      return new Promise((resolve, reject) => {
        dispatch('APICatalogueSearch', {
          query,
          page: 1,
          replaceResults: true,
          replaceFacets: true,
          resetActiveFacets: true,
          resetSelectedIndex: true
        })
          .then(() => { resolve() })
          .catch(error => { reject(error) })
      })
    },
    APICatalogueSearchPage ({ dispatch, state }, page) {
      return new Promise((resolve, reject) => {
        dispatch('APICatalogueSearch', {
          query: state.search.query,
          page,
          replaceResults: false,
          replaceFacets: false,
          resetActiveFacets: false,
          resetSelectedIndex: false
        })
          .then(() => { resolve() })
          .catch(error => { reject(error) })
      })
    },
    APICatalogueSearchNewFacet ({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        dispatch('APICatalogueSearch', {
          query: state.search.query,
          page: 1,
          replaceResults: true,
          replaceFacets: true,
          resetActiveFacets: false,
          resetSelectedIndex: false
        })
          .then(() => { resolve() })
          .catch(error => { reject(error) })
      })
    },
    selectCatalogueResultItem ({ commit }, catalogueResultItem) {
      commit('setSelectedIndex', catalogueResultItem.pubKey)
    },
    deselectCatalogueResultItem ({ commit }) {
      commit('setSelectedIndex', '')
    },
    clearAuth ({ state }) {
      state.auth.accessToken = null
      state.auth.refreshToken = null
    },
    setFacetSetting ({ state, commit }, settings) {
      const { name, sortType, sortOrder } = settings

      if (!name) return

      const newSettings = { ...state.search.facetSettings }

      newSettings[name] = {
        sortOrder,
        sortType
      }

      VueCookies.set('facetSortOrder', newSettings, -1)

      commit('setFacetSettings', newSettings)
    },
    changeLanguage ({ commit }, settings) {
      const { language, i18n, store } = settings
      if (store) window.localStorage.setItem(localStorageLanguageKey, language)
      i18n.locale = language
      commit('setLanguage', language)
    }
  }
})
