<template>
  <div class="shop-basket-total">
    <div class="shop-basket-total-price-label">{{ $t('shop.basket.total') }}:</div>
    <div class="shop-basket-total-price">{{ $filters.currency(totalPrice) }}</div>
    <div class="ml-2">{{ $t('common.excluding-tax') }}</div>
  </div>
</template>

<script>
export default {
  props: {
    books: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    totalPrice () {
      return this.books
        .map(b => b.priceInCents)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    }
  }
}
</script>

<style>
</style>
