import currentSearchModule from './modules/search/currentSearchModule'
import searchOptions from './config/searchOptions'
import sortOptions from './config/sortOptions'
import advancedSearchFieldOptions from './config/advancedSearchFieldOptions'
import searchModulesStore from './modules/search/searchModulesStore'
import VueCookies from 'vue-cookies'
import { defaultFacetGroupOrderSettings } from '@/constants/defaults'
import STATES from '@/constants/states'
import API from '@/axiosAPI'
import acquisitionsConfig from '@/store/config/acquisitionsConfig'

import { Events } from '@/events'

const FACET_SETTINGS_COOKIE = 'facetSortOrder'

export default () => ({
  namespaced: true,
  modules: {
    currentSearch: currentSearchModule,
    searchModules: searchModulesStore
  },
  state: {
    config: {
      searchOptions,
      sortOptions,
      advancedSearchFieldOptions
    },
    facetGroupOrderSettings: VueCookies.get(FACET_SETTINGS_COOKIE) || defaultFacetGroupOrderSettings(),
    lastSearchParams: null,
    acquisitionsLoadingState: STATES.INITIAL,
    acquisitions: [],
    activeResultTab: 'all'
  },
  getters: {
    getFacetGroupOrderSettings (state) {
      return state.facetGroupOrderSettings
    },
    getLastSearchParams (state) {
      return state.lastSearchParams
    },
    getAdvancedFieldOptions (state) {
      const fields = []
      const modules = state.searchModules.modules

      for (const module of modules) {
        const moduleAdvancedFields = module.advancedFields
        if (!moduleAdvancedFields) continue
        for (const field of moduleAdvancedFields) if (!fields.includes(field)) fields.push(field)
      }

      const options = []

      for (const field of fields) {
        options.push({
          label: `metadataField.${field}`,
          value: field
        })
      }

      return options
    }
  },
  actions: {
    storeCurrentSearchParams ({ commit, getters }) {
      const searchParams = { ...getters['currentSearch/getSearchRequestBody'] }
      commit('storeSearchParams', searchParams)
    },
    async search ({ dispatch }) {
      dispatch('storeCurrentSearchParams')
      dispatch('searchModules/search', true)
      Events.$emit('newSearchStarted')
    },
    setFacetGroupOrderSetting ({ commit, state }, facetOrderSetting) {
      commit('setFacetGroupOrderSetting', facetOrderSetting)
      VueCookies.set(FACET_SETTINGS_COOKIE, state.facetGroupOrderSettings, -1)
    },
    changeLastSearchSorting ({ commit, dispatch, state }, sortSettings) {
      if (!state.lastSearchParams) return

      const { sortField, sortOrder } = sortSettings

      if (!sortField || !sortOrder) return

      commit('setSortField', sortField)
      commit('setSortOrder', sortOrder)

      dispatch('searchModules/search', false)
    },
    async fetchAcquisitions ({ state, commit }) {
      if (state.acquisitionsLoadingState !== STATES.INITIAL) return

      commit('setAcquisitionsLoadingState', STATES.LOADING)

      const acquisitionOptions = {
        limitdays: acquisitionsConfig.acquisitionsMaxAgeDays,
        limitamount: acquisitionsConfig.acquisitionsResultLimit
      }

      try {
        const { data } = await API.get('/search/acquisitions', { params: acquisitionOptions })

        commit('setAcquisitions', data)
        commit('setAcquisitionsLoadingState', STATES.READY)
      } catch (error) {
        commit('setAcquisitionsLoadingState', STATES.ERROR)
      }
    },
    async searchExactValueAndField ({ dispatch }, valueAndField) {
      dispatch('currentSearch/createExactFieldSearch', valueAndField)
      await dispatch('search')
    },
    async searchFromUrlQueryObject ({ getters, commit, dispatch }, queryObject) {
      dispatch('currentSearch/newSearch')
      dispatch('currentSearch/setQuery', queryObject.query)
      dispatch('currentSearch/setSearchContext', queryObject.queryType)

      if (queryObject.fields) {
        for (const queryObjectField of queryObject.fields) {
          dispatch('currentSearch/addFilledField', {
            mode: queryObjectField.mode,
            relation: queryObjectField.operator,
            field: queryObjectField.name,
            value: queryObjectField.value
          })
        }
      }

      if (queryObject.sortField && queryObject.sortOrder) {
        dispatch('currentSearch/setSortField', queryObject.sortField)
        dispatch('currentSearch/setSortOrder', queryObject.sortOrder)
      }

      dispatch('storeCurrentSearchParams')

      let connectionId = null
      if (typeof queryObject.connection !== 'undefined' && getters['searchModules/getModule'](`${queryObject.connection}`)) {
        connectionId = `${queryObject.connection}`
      } else if (typeof queryObject.module !== 'undefined') {
        const connectionsForModuleTypeId = getters['searchModules/getModuleByModuleTypeId'](`${queryObject.module}`)
        if (connectionsForModuleTypeId.length) connectionId = `${connectionsForModuleTypeId[0].id}`
      }

      if (connectionId) {
        commit('setActiveResultTab', connectionId)
      } else {
        commit('setActiveResultTab', 'all')
      }

      if (connectionId && queryObject.facets) {
        dispatch('searchModules/searchWithOneModuleFaceted', { moduleId: connectionId, facets: queryObject.facets })
      } else {
        dispatch('searchModules/search', true)
      }
    }
  },
  mutations: {
    setFacetGroupOrderSetting (state, { group, setting }) {
      state.facetGroupOrderSettings[group] = setting
    },
    storeSearchParams (state, params) {
      state.lastSearchParams = params
    },
    setSortField (state, sortField) {
      if (!state.lastSearchParams) return
      state.lastSearchParams.sortField = sortField
    },
    setSortOrder (state, sortOrder) {
      if (!state.lastSearchParams) return
      state.lastSearchParams.sortOrder = sortOrder
    },
    setAcquisitionsLoadingState (state, loadingState) {
      state.acquisitionsLoadingState = loadingState
    },
    setAcquisitions (state, acquisitions) {
      state.acquisitions = acquisitions
    },
    setActiveResultTab (state, tab) {
      state.activeResultTab = tab
    }
  }
})
