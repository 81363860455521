<template>
  <div class="page-container">

    <div class="page-content" @scroll="handleScrollEvent">

      <div class="page-content-container">
        <h1>{{ $t('shop.checkout.title') }}</h1>

        <div>
          <ShopBasketItem ref="basketItems"
              v-for="book in booksInBasket" :key="book.isbn"
              :book="book" />
        </div>

        <ShopBasketTotal :books="booksInBasket" v-if="booksInBasket.length" class="mb-3" />

        <form @submit.prevent="submit">
          <div class="mb-3">
            <div class="form-group">
              <input type="checkbox" id="accept-general" v-model="general" true-value="true" false-value="false">
              <i18n-t keypath="shop.checkout.general-agreements.text" tag="label" for="accept-general" scope="global">
                <a href="https://www.square.nl/content/algemene_leveringsvoorwaarden.pdf" target="_blank">{{ $t('shop.checkout.general-agreements.link') }}</a>
              </i18n-t>
            </div>
            <div class="form-group">
              <input type="checkbox" id="accept-privacy" v-model="privacy" true-value="true" false-value="false">
              <i18n-t keypath="shop.checkout.privacy-agreements.text" tag="label" for="accept-privacy" scope="global">
                <a href="https://www.square.nl/privacy" target="_blank">{{ $t('shop.checkout.privacy-agreements.link') }}</a>
              </i18n-t>
            </div>
          </div>
          <Alert :type="'danger'"
              v-if="errorOrderingBooks"
              class="mb-3">
            {{ $t('shop.checkout.order-failed') }}
          </Alert>
          <Alert :type="'danger'"
              v-if="v$.$dirty && v$.$invalid"
              class="mb-3">
            {{ $t('shop.checkout.validation.accept-terms') }}
          </Alert>

          <div class="d-flex align-items-center">
            <button
                v-if="booksInBasket.length"
                type="submit"
                class="input-button input-button-primary"
                :disabled="isOrderingBooks">{{ $t('shop.checkout.complete-order') }}</button>
            <InlineLoadingIndicator v-if="isOrderingBooks" class="ml-2" />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Alert from '@/components/Alert.vue'
import InlineLoadingIndicator from '@/components/InlineLoadingIndicator'
import ShopBasketItem from '@/components/shop-basket/ShopBasketItem.vue'
import ShopBasketTotal from '@/components/shop-basket/ShopBasketTotal.vue'
import debounceMixin from '@/mixins/debounce'
import useVuelidate from '@vuelidate/core'
import { sameAs } from '@vuelidate/validators'

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      general: false,
      privacy: false
    }
  },
  computed: {
    ...mapState({
      booksInBasket: state => state.shop.basket.books,
      isOrderingBooks: state => state.shop.basket.order.isLoading,
      errorOrderingBooks: state => state.shop.basket.order.errorLoading
    }),
    basketItemElements () {
      return this.$refs.basketItems || []
    }
  },
  methods: {
    ...mapActions('shop/basket', [
      'REMOVE_BOOK_FROM_BASKET',
      'ORDER_BOOKS_IN_BASKET'
    ]),
    handleScrollEvent () {
      this.debounce(() => this.loadImagesForBooksInViewPort())
    },
    loadImagesForBooksInViewPort () {
      for (const basketItemElement of this.basketItemElements) {
        if (basketItemElement.isInViewPort()) {
          basketItemElement.loadBookCover()
        }
      }
    },
    orderBooksInBasket () {
      this.ORDER_BOOKS_IN_BASKET().then(() => {
        this.$router.push({ name: 'shop_success' })
      }).catch(() => {})
    },
    submit () {
      this.v$.$touch()
      if (!this.v$.$invalid) {
        this.orderBooksInBasket()
      }
    }
  },
  mounted () {
    this.loadImagesForBooksInViewPort()
  },
  validations: {
    general: {
      sameAs: sameAs('true')
    },
    privacy: {
      sameAs: sameAs('true')
    }
  },
  components: {
    Alert,
    InlineLoadingIndicator,
    ShopBasketItem,
    ShopBasketTotal
  },
  mixins: [
    debounceMixin
  ]
}
</script>

<style>
</style>
