<template>
  <div class="result-container">
    <div class="tab-container">
      <module-tabbar v-model:activeTab="activeTab" :modules="modules"></module-tabbar>
      <div v-if="activeTab !== 'all'" class="sort-container">
        <div>{{ capitalize($t('sort by')) }}</div>
        <div>
          <custom-select v-model="sortField" :options="sortFieldOptions"></custom-select>
        </div>
        <div>
          <custom-select v-model="sortOrder" :options="sortOrderOptions"></custom-select>
        </div>
      </div>
      <div v-if="activeTab !== 'all'" class="listmode-container">
        <div class="choice-container">
          <div
            class="choice-option"
            :class="{ 'choice-option-active': resultListMode === 'list' }"
            :title="capitalize($t('resultlistmode.label')) + ': ' + $t('resultlistmode.list')"
            @click="resultListMode = 'list'"
          >
            <img src="/img/icons/catalogue_mode_list.png" alt="list">
          </div>
          <div
            class="choice-option"
            :class="{ 'choice-option-active': resultListMode === 'tiles' }"
            :title="capitalize($t('resultlistmode.label')) + ': ' + $t('resultlistmode.tiles')"
            @click="resultListMode = 'tiles'"
          >
            <img src="/img/icons/catalogue_mode_tiles.png" alt="tiles">
          </div>
        </div>
      </div>
    </div>
    <div class="tabcontent-container">
        <module-aggregate-view
          v-if="activeTab === 'all'"
          :modules="modules"
          @select="onSelectPublication"
          @navigateToTab="setActiveTab"
        ></module-aggregate-view>
        <module-result-view
          v-if="activeTab !== 'all'"
          :moduleId="activeTab"
          :selectedPublication="selectedPublication"
          :mode="resultListMode"
          @select="onSelectPublication"
        ></module-result-view>
        <Transition name="paneltransition">
          <module-result-detail
            v-show="detailPanelOpen"
            ref="detailLoader"
            :actionAddToBookshelf="true"
            @close="closeDetailPanel"
          ></module-result-detail>
        </Transition>
    </div>
  </div>
</template>

<script>
import ModuleTabbar from '@/components/module-tabbar/ModuleTabbar'
import ModuleAggregateView from '@/components/module-aggregate/ModuleAggregateView'
import ModuleResultView from '@/components/module-results/ModuleResultView'
import CustomSelect from '@/components/form-elements/CustomSelect'
import ModuleResultDetail from '@/components/module-results/ModuleResultDetail'
import { mapState } from 'vuex'
import { Events } from '@/events'
import queryObjectValidator from '@/utilities/queryObjectValidator'

export default {
  name: 'results-view',
  computed: {
    ...mapState('searchStore/searchModules', {
      modules: state => state.modules
    }),
    ...mapState('searchStore', {
      sortFieldOptions: state => state.config.sortOptions.sortFieldOptions,
      sortOrderOptions: state => state.config.sortOptions.sortOrderOptions
    }),
    sortField: {
      get () { return this.$store.state.searchStore.lastSearchParams?.sortField },
      set (value) {
        if (value === this.sortField) return
        this.$store.dispatch('searchStore/changeLastSearchSorting', {
          sortField: value,
          sortOrder: this.sortOrder
        })
      }
    },
    sortOrder: {
      get () { return this.$store.state.searchStore.lastSearchParams?.sortOrder },
      set (value) {
        if (value === this.sortOrder) return
        this.$store.dispatch('searchStore/changeLastSearchSorting', {
          sortField: this.sortField,
          sortOrder: value
        })
      }
    },
    activeTab: {
      get () { return this.$store.state.searchStore.activeResultTab },
      set (value) {
        this.$store.commit('searchStore/setActiveResultTab', value)
      }
    }
  },
  data: () => ({
    selectedPublication: null,
    detailPanelOpen: false,
    resultListMode: 'list'
  }),
  methods: {
    onSelectPublication (publicationInfo) {
      if (!this.$refs.detailLoader?.loadPublication) return

      this.$refs.detailLoader.loadPublication(publicationInfo)
      this.detailPanelOpen = true
      this.selectedPublication = publicationInfo.publication
    },
    closeDetailPanel () {
      this.detailPanelOpen = false
      this.selectedPublication = null
    },
    setActiveTab (tab) {
      if (this.activeTab !== tab) this.closeDetailPanel()
      this.activeTab = tab
    },
    onNewSearch () {
      this.closeDetailPanel()
      const targetTab = (this.modules.length === 1) ? this.modules[0].id : 'all'
      this.setActiveTab(targetTab)
    },
    launchSearchFromUrl () {
      const queryJsonString = this.$route.query?.query
      let queryJson = null
      try {
        if (queryJsonString) { queryJson = JSON.parse(queryJsonString) }
      } catch (e) {
        return this.onSetQueryFromUrlError(e.message)
      }
      if (!queryJson) {
        return this.onSetQueryFromUrlError('Query urlparam empty')
      }

      const validationResult = queryObjectValidator.validate(queryJson)
      if (validationResult.error) {
        return this.onSetQueryFromUrlError(validationResult.error)
      }

      this.$store.dispatch('searchStore/searchFromUrlQueryObject', validationResult.value)
      this.$router.push({ name: 'results' })
    },
    onSetQueryFromUrlError (errorMessage) {
      console.warn(errorMessage)
    }
  },
  components: {
    'module-tabbar': ModuleTabbar,
    'module-aggregate-view': ModuleAggregateView,
    'module-result-view': ModuleResultView,
    'custom-select': CustomSelect,
    'module-result-detail': ModuleResultDetail
  },
  mounted () {
    const _this = this
    if (this.activeTab === 'all' && this.modules.length === 1) this.setActiveTab(this.modules[0].id)
    if (this.$route.query?.query) this.launchSearchFromUrl()
    Events.$on('newSearchStarted', _this.onNewSearch)
  },
  beforeUnmount () {
    const _this = this
    Events.$off('newSearchStarted', _this.onNewSearch)
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/css/_constants";

  .result-container {
    height: calc(100% - #{$header_height});

    .tab-container {
      height: $search_tabbar_height;
      display: flex;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.46);
      z-index: 1;

      .sort-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        flex-grow: 0;
        flex-shrink: 0;

        & > div {
          flex-grow: 0;
          flex-shrink: 0;
        }
      }

      .listmode-container {
        padding-top: 2px;
        margin: 0 20px;
      }
    }

    .tabcontent-container {
      height: calc(100% - $search_tabbar_height);
    }
  }

  .paneltransition{
    &-enter-from, &-leave-to {
      right: -500px;
    }

    &-enter-active {
      transition: right 0.5s ease;
    }

    &-leave-active {
      transition: right 0.2s ease;
    }
  }

  .choice {
    &-container {
      display: flex;
      flex-direction: row;
    }

    &-option {
      padding: 6px 6px 2px 6px;
      border-top: 1px solid #ddd;
      border-bottom: 4px solid #ddd;
      cursor: pointer;
      white-space: nowrap;

      &:first-child {
        border-left: 1px solid #ddd;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-right: 1px solid #ddd;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      &-active {
        border-bottom-color: $main_light;
      }

      &:hover:not(.choice-option-active) {
        border-bottom-color: $grey_dark;
      }
    }
  }
</style>
