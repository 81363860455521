<template>
  <div class="list-lazy-loader">
    <img v-if="isLoading" src="/img/anim_loading_grey.gif">
    <div v-if="!isLoading && thereIsMoreToLoad">
      <button class="input-button input-button-outlined" @click="tryToTriggerLazyLoad">{{ $t('Load additional results') }}</button>
    </div>
    <div v-if="!isLoading && !thereIsMoreToLoad">{{ $t('All results have been loaded') }}</div>
  </div>
</template>

<script>
import viewPortMixin from '@/mixins/viewPort'

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    thereIsMoreToLoad: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onScroll () {
      this.tryToTriggerLazyLoad()
    },
    tryToTriggerLazyLoad () {
      if (
        this.isInViewPort() &&
        !this.isLoading &&
        this.thereIsMoreToLoad
      ) {
        this.$emit('triggerLazyLoad')
      }
    }
  },
  mixins: [viewPortMixin],
  emits: ['triggerLazyLoad']
}
</script>

<style>
</style>
