<template>
    <div class="book-catalogue-container"
        :class="[tileView ? 'book-catalogue-mode-tile' : 'book-catalogue-mode-list', isLoadingFirstPage ? 'book-catalogue-initial' : '']">

        <transition name="loading-fade">
            <div id="book-catalogue-blanket" class="text-center book-catalogue-blanket" v-if="isLoadingFirstPage">
              <img class="book-catalogue-loadingimg" src="/img/anim_searching_white.gif" />
              <p class="text-italic">{{ $t('Searching') }}...</p>
            </div>
        </transition>

        <div class="text-center book-catalogue-blanket" v-if="errorLoading">
            <img class="book-catalogue-loadingimg" src="/img/searching_500.png" style="opacity: 0.8;" />
            <p>{{ $t('An error occurred in our server while searching in the catalogue') }}</p>
            <p>{{ $t('Please try again later') }}</p>
        </div>

        <div v-if="!isLoading && !errorLoading && !totalAmountOfBooks" class="book-catalogue-noresult">
            {{ $t('There are no results for this query') }}
        </div>

        <div v-if="!isLoadingFirstPage && !errorLoading && totalAmountOfBooks" @scroll.passive="handleScrollEvent" class="book-catalogue-list">
            <div class="book-catalogue-resultcount">{{ totalAmountOfBooks }} {{ (totalAmountOfBooks > 1) ? $t('results found') : $t('result found') }}</div>

            <slot name="catalogueItems"></slot>
            <slot name="listLazyLoader"></slot>

        </div>

    </div>
</template>

<script>
import debounceMixin from '@/mixins/debounce'

export default {
  props: {
    totalAmountOfBooks: {
      type: Number,
      default: () => 0
    },
    isLoadingFirstPage: {
      type: Boolean,
      default: () => false
    },
    isLoadingAdditionalPages: {
      type: Boolean,
      default: () => false
    },
    errorLoading: {
      type: Boolean,
      default: () => false
    },
    tileView: {
      type: Boolean,
      default: () => true
    }
  },
  computed: {
    isLoading () {
      return this.isLoadingFirstPage || this.isLoadingAdditionalPages
    }
  },
  methods: {
    handleScrollEvent () {
      this.debounce(() => this.$emit('debouncedScroll'))
    }
  },
  mixins: [
    debounceMixin
  ],
  emits: ['debouncedScroll']
}
</script>

<style>
</style>
