<template>
  <div class="moduletab" :class="{ 'moduletab-active': isActive }" @click="$emit('select')">
    <span class="moduletab-modulename">
      {{ moduleName }}
    </span>
    <span v-if="loadingState === LOADING_STATES.READY" class="moduletab-status moduletab-resultcount">
      ({{ amountDisplay }})
    </span>
    <span v-if="loadingState === LOADING_STATES.LOADING" class="moduletab-status moduletab-loading">
      <spinner class="moduletab-loader" :size="12" :width="2"></spinner>
    </span>
    <span v-if="loadingState === LOADING_STATES.ERROR" class="moduletab-status moduletab-error">
      !
    </span>
  </div>
</template>

<script>
import Spinner from '@/components/utilities/Spinner'
import { mapState } from 'vuex'
import STATES from '@/constants/states'

export default {
  name: 'module-tabbar-tab',
  props: {
    moduleId: [Number, String],
    isActive: Boolean
  },
  emits: ['select'],
  data: () => ({
    LOADING_STATES: STATES
  }),
  computed: {
    ...mapState('searchStore/searchModules', {
      moduleName (state) { return state[this.moduleId].moduleName },
      loadingState (state) { return state[this.moduleId].loadingState },
      totalAmount (state) { return state[this.moduleId].totalAmount },
      filteredAmount (state) { return state[this.moduleId].filteredAmount }
    }),
    amountDisplay () {
      return (this.filteredAmount === 0 ? '' : `${this.filteredAmount}/`) + this.totalAmount
    }
  },
  components: {
    spinner: Spinner
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/css/_constants";
  @import "../../assets/css/components/moduleTab";
</style>
