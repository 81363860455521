import API from '@/axiosAPI'
import { defaultResultsPerPage } from '@/constants/defaults'
import searchConfig from '@/store/config/searchConfig'
import STATES from '@/constants/states'

const initializeNewSearch = (commit) => {
  resetListPosition(commit)

  commit('setTotalAmount', 0)
  commit('setFilteredAmount', 0)
  commit('setFacets', {})
  commit('setFacetChangesAdd', {})
  commit('setFacetChangesRemove', {})
  commit('setActiveFacets', {})
  commit('setShortlist', [])
}

const resetListPosition = (commit) => {
  commit('setCurrentPage', 0)
  commit('setPublications', [])
  commit('setAllPagesLoaded', false)
}

const prepareSearchParams = (params, moduleId, page, activeFacets = {}) => {
  const { sortField, sortOrder, ...searchParams } = params

  const paginationOptions = {
    page,
    resultsPerPage: defaultResultsPerPage
  }

  const sortString = `${sortField} ${sortOrder}`

  return {
    ...searchParams,
    paginationOptions,
    facets: activeFacets,
    sorting: [sortString],
    connections: [moduleId]
  }
}

const setSearchErrorState = (commit) => {
  commit('setCurrentPage', 0)
  commit('setTotalAmount', 0)
  commit('setPublications', [])
  commit('setShortlist', [])
  commit('setFacets', {})
  commit('setFacetChangesAdd', {})
  commit('setFacetChangesRemove', {})
  commit('setActiveFacets', {})
  commit('setLoadingState', STATES.ERROR)
}

const setSearchEmptyState = (commit) => {
  commit('setCurrentPage', 0)
  commit('setTotalAmount', 0)
  commit('setPublications', [])
  commit('setShortlist', [])
  commit('setFacets', {})
  commit('setFacetChangesAdd', {})
  commit('setFacetChangesRemove', {})
  commit('setActiveFacets', {})
  commit('setLoadingState', STATES.READY)
}

const getLocalSearchParams = (state, rootGetters, page = 0) => {
  const globalSearchParams = { ...rootGetters['searchStore/getLastSearchParams'] }
  return prepareSearchParams(globalSearchParams, state.moduleId, page, state.activeFacets)
}

const performSearch = async ({ state, rootGetters, commit }, page, setTotalAmount, setFilteredAmount, setShortlist) => {
  const localSearchParams = getLocalSearchParams(state, rootGetters, page)

  commit('setLoadingState', STATES.LOADING)

  try {
    const { data } = await API.post('/search', localSearchParams)
    const result = data[0]

    if (result.error) {
      if (result.status === 400) {
        setSearchEmptyState(commit)
        return
      } else {
        setSearchErrorState(commit)
        return
      }
    }

    if (setTotalAmount) commit('setTotalAmount', result.total)
    if (setFilteredAmount) commit('setFilteredAmount', result.total === state.totalAmount ? 0 : result.total)
    if (setShortlist) commit('setShortlist', result.publications.slice(0, searchConfig.shortlistLength))

    commit('addPublications', result.publications)
    commit('setFacets', result.facets)
    commit('setCurrentPage', page)

    if (result.publications.length < localSearchParams.paginationOptions.resultsPerPage) {
      commit('setAllPagesLoaded', true)
    }

    commit('setLoadingState', STATES.READY)
  } catch (error) {
    setSearchErrorState(commit)
  }
}

const createUrlQueryObject = (state, rootGetters) => {
  const localSearchParams = getLocalSearchParams(state, rootGetters)
  const queryObject = {
    query: localSearchParams.query,
    queryType: localSearchParams.queryType,
    connection: localSearchParams.connections[0]
  }

  if (localSearchParams.facets && Object.keys(localSearchParams.facets).length) {
    queryObject.facets = localSearchParams.facets
  }

  if (localSearchParams.fields && localSearchParams.fields.length) {
    queryObject.fields = localSearchParams.fields
  }

  if (localSearchParams.sorting && localSearchParams.sorting.length) {
    const [sortField, sortOrder] = localSearchParams.sorting[0].split(' ')
    queryObject.sortField = sortField
    queryObject.sortOrder = sortOrder
  }

  return queryObject
}

const applyFacetChangesToFacetList = (currentFacets, facetChanges) => {
  const appliedFacets = {}

  for (const category of Object.keys(currentFacets)) {
    const values = currentFacets[category].filter(val => !facetChanges.remove[category]?.includes(val))
    if (values.length) appliedFacets[category] = values
  }

  for (const category of Object.keys(facetChanges.add)) {
    if (!appliedFacets[category]) {
      appliedFacets[category] = [...facetChanges.add[category]]
      continue
    }

    appliedFacets[category] = [...appliedFacets[category], ...facetChanges.add[category].filter(val => !currentFacets[category].includes(val))]
  }

  return appliedFacets
}

export default (moduleId = null, moduleName = '') => ({
  namespaced: true,
  state: () => ({
    moduleName,
    moduleId,
    loadingState: STATES.READY,
    totalAmount: 0,
    filteredAmount: 0,
    currentPage: 0,
    publications: [],
    shortlist: [],
    facets: {},
    facetChanges: { add: {}, remove: {} },
    activeFacets: {},
    allPagesLoaded: true
  }),
  getters: {
    getUrlQueryObject (state, _, __, rootGetters) {
      return createUrlQueryObject(state, rootGetters)
    },
    getAppliedFacetChanges (state) {
      return applyFacetChangesToFacetList(state.activeFacets, state.facetChanges)
    },
    hasFacetChanges (state) {
      return [...Object.keys(state.facetChanges.add), ...Object.keys(state.facetChanges.remove)].length !== 0
    }
  },
  actions: {
    async search (context, isNewSearch) {
      if (isNewSearch) {
        initializeNewSearch(context.commit)
      } else {
        resetListPosition(context.commit)
      }

      await performSearch(context, context.state.currentPage, isNewSearch, !isNewSearch, isNewSearch)
    },
    async nextPage (context) {
      performSearch(context, context.state.currentPage + 1, false, false, false)
    },
    setFacetState ({ commit }, { group, value, active }) {
      if (active) {
        commit('addActiveFacet', { group, value })
      } else {
        commit('removeActiveFacet', { group, value })
      }
    },
    resetActiveFacets ({ commit }) {
      commit('setActiveFacets', {})
    },
    setFacetChangeState ({ commit }, { group, value, active }) {
      if (active) {
        commit('facetChangeAdd', { group, value })
      } else {
        commit('facetChangeRemove', { group, value })
      }
    },
    applyFacetChanges ({ dispatch, commit, state }) {
      const newFacets = applyFacetChangesToFacetList(state.activeFacets, state.facetChanges)
      commit('setActiveFacets', newFacets)
    },
    resetFacetChanges ({ commit }) {
      commit('setFacetChangesAdd', {})
      commit('setFacetChangesRemove', {})
    },
    async searchDetail ({ state }, publicationId) {
      const params = {
        connectionId: state.moduleId,
        publicationId
      }

      try {
        const { data } = await API.get('/search/detail', { params })

        if (!data) return false

        return data
      } catch (error) {
        console.error(error)
        return false
      }
    },
    async searchAndFilter (context, facets = {}) {
      initializeNewSearch(context.commit)
      await performSearch(context, context.state.currentPage, true, false, true)
      context.commit('setActiveFacets', facets)
      resetListPosition(context.commit)
      await performSearch(context, context.state.currentPage, false, true, false)
    }
  },
  mutations: {
    setLoadingState (state, loadingState) {
      state.loadingState = loadingState
    },
    setTotalAmount (state, amount) {
      state.totalAmount = amount
    },
    setFilteredAmount (state, amount) {
      state.filteredAmount = amount
    },
    setCurrentPage (state, page) {
      state.currentPage = page
    },
    setPublications (state, publications) {
      state.publications = publications
    },
    addPublications (state, publications) {
      state.publications.push(...publications)
    },
    setShortlist (state, shortlist) {
      state.shortlist = shortlist
    },
    setFacets (state, facets) {
      state.facets = facets
    },
    setActiveFacets (state, activeFacets) {
      state.activeFacets = activeFacets
    },
    setAllPagesLoaded (state, allPagesLoaded) {
      state.allPagesLoaded = allPagesLoaded
    },
    addActiveFacet (state, { group, value }) {
      if (!state.activeFacets[group]) state.activeFacets[group] = []

      if (state.activeFacets[group].indexOf(value) === -1) {
        state.activeFacets[group].push(value)
      }
    },
    removeActiveFacet (state, { group, value }) {
      if (!state.activeFacets[group]) return

      state.activeFacets[group] = state.activeFacets[group].filter(facet => facet !== value)
      if (state.activeFacets[group].length === 0) delete state.activeFacets[group]
    },
    setFacetChangesAdd (state, changesAddState) {
      state.facetChanges.add = changesAddState
    },
    setFacetChangesRemove (state, changesRemoveState) {
      state.facetChanges.remove = changesRemoveState
    },
    facetChangeAdd (state, { group, value }) {
      if (state.facetChanges.remove[group]?.includes(value)) {
        state.facetChanges.remove[group] = state.facetChanges.remove[group].filter(v => v !== value)
        if (!state.facetChanges.remove[group].length) delete state.facetChanges.remove[group]
      }

      if (state.activeFacets[group]?.includes(value)) return

      if (!state.facetChanges.add[group]) state.facetChanges.add[group] = []
      if (!state.facetChanges.add[group].includes(value)) state.facetChanges.add[group].push(value)
    },
    facetChangeRemove (state, { group, value }) {
      if (state.facetChanges.add[group]?.includes(value)) {
        state.facetChanges.add[group] = state.facetChanges.add[group].filter(v => v !== value)
        if (!state.facetChanges.add[group].length) delete state.facetChanges.add[group]
      }

      if (!state.activeFacets[group]?.includes(value)) return

      if (!state.facetChanges.remove[group]) state.facetChanges.remove[group] = []
      if (!state.facetChanges.remove[group].includes(value)) state.facetChanges.remove[group].push(value)
    }
  }
})
