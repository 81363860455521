<template>
  <div class="publication-cover">
    <lazy-loading-image
      v-if="url"
      ref="cover"
      class="publication-cover-image"
      :url="url"
    >
      <template v-slot:status-loading>
        <div class="publication-cover-loading">
          <spinner :size="40" :width="4"></spinner>
        </div>
      </template>
      <template v-slot:status-failed>
        <img src="/img/cover_placeholder.jpg" class="publication-cover-placeholder" />
      </template>
    </lazy-loading-image>
    <img v-else src="/img/cover_placeholder.jpg" class="publication-cover-placeholder" />
  </div>
</template>

<script>
import LazyLoadingImg from '@/components/LazyLoadingImg'
import Spinner from '@/components/utilities/Spinner'

export default {
  name: 'publication-cover',
  props: {
    url: String
  },
  methods: {
    loadImage () {
      if (this.$refs.cover?.loadImage) this.$refs.cover.loadImage()
    }
  },
  components: {
    'lazy-loading-image': LazyLoadingImg,
    spinner: Spinner
  }
}
</script>

<style lang="scss" scoped>
  .publication-cover {
    width: 100px;
    height: 150px;

    &-image {
      width: 100%;
      height: 100%;

      &.loaded {
        box-shadow: -2px 4px 7px rgba(0, 0, 0, 0.16);
        border-radius: 4px 0 0 4px;
        overflow: hidden;

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 4px;
          box-shadow: 4px 0 2px -2px inset rgba(0, 0, 0, 0.2), 1px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }

    &-placeholder {
      width: 100%;
      height: 100%;
      background-color: white;
    }

    &-loading {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: white;
    }
  }
</style>
