import searchModuleStore from './searchModuleStore'
import API from '@/axiosAPI'

export default {
  namespaced: true,
  state: {
    modules: []
  },
  getters: {
    getModule (state) {
      return (id) => state.modules.find(module => module.id === id)
    },
    getModuleByModuleTypeId (state) {
      return (typeId) => state.modules.filter(module => module.module === typeId)
    }
  },
  actions: {
    async initialise ({ commit }) {
      const { data } = await API.post('/search/moduleinfo')

      for (const module of data) {
        this.registerModule(['searchStore', 'searchModules', module.id], searchModuleStore(module.id, module.name))
      }

      commit('setModules', data)
    },
    search ({ state, dispatch }, isNewSearch) {
      for (const module of state.modules) {
        dispatch(`${module.id}/search`, isNewSearch)
      }
    },
    searchWithOneModuleFaceted ({ state, dispatch }, { moduleId, facets }) {
      for (const module of state.modules) {
        if (module.id === moduleId) {
          dispatch(`${module.id}/searchAndFilter`, facets)
        } else {
          dispatch(`${module.id}/search`, true)
        }
      }
    }
  },
  mutations: {
    setModules (state, modules) {
      state.modules = modules
    }
  }
}
