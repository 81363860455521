<template>
    <div class="header-container">
      <router-link
        to="/"
        @click.prevent="navigateHome()"
        :title="$t('header.home')"
      >
        <img class="header-logo" :src="logoUrl" alt="logo" />
      </router-link>

      <router-link
        v-if="showResultsNavigationButton"
        to="/search"
        class="header-button"
        @click.prevent="navigateResults()"
      >{{ $t('header.close') }}</router-link>

      <router-link
        v-if="showNavigationToShopViews"
        to="/shop/library"
        class="header-button"
      >{{ $t('header.shop') }}</router-link>

      <div v-if="title" class="vr"></div>

      <div v-if="title" id="appearance-title" class="appearance-title" v-html="parseDOM(title)"></div>

      <div v-if="showHeaderSearchBar" class="header-searchbar">
        <search-bar></search-bar>
      </div>

      <div class="flex-grow-1"></div>

      <router-link
        to="/shop/basket"
        class="header-button"
        v-if="showNavigationToShopViews"
      >
        <span v-if="basketItemCount > 0" class="header-basket-count">{{ basketItemCount }}</span>
        <img src="/img/icons/cart.png" class="header-icon"/>
      </router-link>

      <div v-if="clientSettings.enableInfotext && clientSettings.infotext" class="header-button header-submenu-trigger">
        <img src="/img/icons/information.png" class="header-icon" alt="information" />
        <div class="header-submenu-container">
          <div class="header-submenu-scroll">
            <rich-text-editor class="informationtext" :content="clientSettings.infotext" :readonly="true" />
          </div>
        </div>
      </div>

      <router-link
        to="/bookshelf"
        class="header-button"
        v-if="showNavigationToBookshelf"
        :title="capitalize($t('bookshelf.bookshelves'))"
      >
        <img src="/img/icons/bookshelf.png" class="header-icon" :alt="capitalize($t('bookshelf.bookshelves'))"/>
      </router-link>

      <div class="header-button header-submenu-trigger">
        <img src="/img/icons/menu.png" class="header-icon" alt="menu" />
        <div class="header-submenu-container">
          <usermenu v-if="$store.state.initialised"></usermenu>
        </div>
      </div>
    </div>
</template>
<script>
import SearchBar from '@/components/search/SearchBar'
import UserMenu from '@/components/UserMenu'
import RichTextEditor from '@/components/form-elements/RichTextEditor'
import { mapState } from 'vuex'
import { Events } from '@/events'
import domparser from '@/mixins/domparser'

export default {
  computed: {
    ...mapState({
      booksInBasket: state => state.shop.basket.books
    }),
    logoUrl () {
      const clientLogoUrl = this.$store.getters['settings/getClientLogo']
      return clientLogoUrl || '/img/hl-logo-full.svg'
    },
    title () {
      if (!this.$store.state.reader) return null
      return this.$store.getters['reader/getAppearanceTitle']
    },
    basketItemCount () {
      return this.booksInBasket.length
    },
    showNavigationToShopViews () {
      const regexForShopRoutes = /^\/shop\/.*/
      const currentPageIsPartOfShop = this.$route.path.match(regexForShopRoutes)
      return !!(currentPageIsPartOfShop || this.basketItemCount)
    },
    showNavigationToBookshelf () {
      return this.$store.getters.userHasUserRole
    },
    showHeaderSearchBar () {
      return this.$route.name === 'results'
    },
    showResultsNavigationButton () {
      return this.$route.name === 'reader'
    },
    clientSettings () {
      return this.$store.getters['settings/getClientSettings']
    }
  },
  methods: {
    navigateHome () {
      if (this.$route.name === 'home') {
        Events.$emit('libraryReset')
      } else {
        this.$router.push({ name: 'home' })
      }
    },
    navigateResults () {
      this.$router.push({ name: 'search' })
    }
  },
  mixins: [domparser],
  components: {
    'search-bar': SearchBar,
    usermenu: UserMenu,
    'rich-text-editor': RichTextEditor
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/css/_constants";

  .header-searchbar {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 10px;
  }

  .informationtext {
    width: $informationtext_width;
  }

  .header-submenu-scroll {
    width: 100%;
    max-height: calc(100vh - 100px);
    overflow-x: hidden;
    overflow-y: auto;
  }
</style>
