<template>
  <div v-if="loadingState === LOADING_STATES.LOADING && publications.length === 0" class="module-result-state">
    <img src="/img/anim_searching_white.gif" />
    <div>{{ capitalize($t('searching')) }}...</div>
  </div>
  <div v-else-if="loadingState === LOADING_STATES.ERROR" class="module-result-state">
    <img src="/img/searching_500.png" />
    <div>{{ capitalize($t('there has been an error while searching')) }}</div>
  </div>
  <div v-else-if="!hasPublications && !hasActiveFacets" class="module-result-state">
    <div class="module-result-empty">{{ capitalize($t('there are no results for this query')) }}</div>
  </div>
  <div v-else class="module-result-container">
    <div class="module-result-sidebar">
      <div class="module-result-options">
        <button
          v-if="hasFacetChanges"
          class="input-button input-button-primary input-button-compact"
          @click="applyFacetChanges"
        >{{ capitalize($t('facets.apply')) }}</button>
        <button
          v-if="hasFacetChanges"
          class="input-button input-button-compact"
          @click="resetFacetChanges"
          :title="capitalize($t('facets.cancelChange'))"
        >
          <img src="/img/icons/cancel.png" class="input-button-image input-button-image-compact"/>
        </button>
        <button
          v-if="hasActiveFacets"
          class="input-button input-button-compact"
          @click="resetActiveFacets"
          :title="capitalize($t('facets.reset'))"
        >
          <span v-if="!hasFacetChanges">{{ capitalize($t('facets.reset')) }}</span>
          <img v-else src="/img/icons/delete.png" class="input-button-image input-button-image-compact" />
        </button>
        <div class="module-result-option-spacer"></div>
        <button
          class="input-button input-button-compact"
          @click="addQueryToBookshelf"
          :title="capitalize($t('bookshelf.addToBookshelf'))"
        >
          <img src="/img/icons/bookshelf.png" class="input-button-image input-button-image-compact" :alt="capitalize($t('bookshelf.addToBookshelf'))" />
        </button>
        <div class="module-result-option" :title="capitalize($t('share'))">
          <img class="module-result-option-image" src="/img/icons/share.png" alt="share">
          <div class="module-result-option-foldout">
            <h3>{{ capitalize($t('share')) }}</h3>
            <copy-clipboard class="module-result-share" :copyvalue="shareUrl" />
          </div>
        </div>
      </div>
      <module-facets
        class="module-result-facets"
        :facets="facets"
        :activeFacets="activeFacets"
        :appliedFacets="appliedFacets"
        @toggle="(facetState) => onFacetToggle(facetState)"
      ></module-facets>
      <div v-if="loadingState === LOADING_STATES.LOADING" class="facet-loading-cover"></div>
    </div>
    <div class="result-container">
      <div v-if="checkedResults.length" class="result-selectedactions-container">
        <div>{{ capitalize($t(checkedResults.length === 1 ? 'selectedResult' : 'selectedResults', { amount: checkedResults.length })) }}:</div>
        <button class="input-button input-button-compact" :title="capitalize($t('bookshelf.addToBookshelf'))" @click="addCheckedResultsToBookshelf">
          <img src="/img/icons/bookshelf.png" class="input-button-image input-button-image-compact" :alt="capitalize($t('bookshelf.addToBookshelf'))" />
        </button>
      </div>
      <div class="result-resultlist-container" @scroll="onResultlistScroll">
        <div v-if="!hasPublications" class="module-result-empty">{{ capitalize($t('there are no results for this query')) }}</div>
        <div class="result-resultlist" :class="{ 'result-resultlist-tiles': mode === 'tiles' }" ref="resultlist">
          <module-result
            v-for="publication of publications"
            :key="publication.id"
            :publication="publication"
            :moduleId="moduleId"
            :mode="mode"
            :class="{ 'result-selected': selectedPublication && selectedPublication === publication.id }"
            :showCheckboxes="showCheckboxes"
            v-model:check="checkedResults"
            @click="onPublicationClick(publication.id)"
          ></module-result>
        </div>
        <div v-if="hasPublications" class="actions">
          <button v-if="loadingState === LOADING_STATES.LOADING" class="input-button input-button-outlined" disabled>
            {{ capitalize($t('loading additional results')) }}...
          </button>
          <button v-else-if="!allPagesLoaded" @click="loadNextResults" class="input-button input-button-outlined">
            {{ capitalize($t('load additional results')) }}
          </button>
          <button v-else class="input-button input-button-outlined" disabled>
            {{ capitalize($t('all results have been loaded')) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModuleFacets from '@/components/module-facets/ModuleFacets'
import ModuleResult from '@/components/module-results/ModuleResult'
import CopyClipboard from '@/components/form-elements/CopyClipboard'
import { mapState } from 'vuex'
import { Events } from '@/events'
import STATES from '@/constants/states'
import ITEM_TYPES from '@/constants/bookshelfItemType'

export default {
  name: 'module-results-view',
  props: ['moduleId', 'selectedPublication', 'mode'],
  emits: ['select'],
  watch: {
    moduleId (newId, oldId) {
      if (newId !== oldId) this.checkedResults = []
    }
  },
  computed: {
    ...mapState('searchStore/searchModules', {
      loadingState (state) { return state[this.moduleId].loadingState },
      allPagesLoaded (state) { return state[this.moduleId].allPagesLoaded },
      publications (state) { return state[this.moduleId].publications },
      facets (state) { return state[this.moduleId].facets },
      activeFacets (state) { return state[this.moduleId].activeFacets }
    }),
    appliedFacets () {
      return this.$store.getters[`searchStore/searchModules/${this.moduleId}/getAppliedFacetChanges`]
    },
    hasActiveFacets () {
      return Object.keys(this.activeFacets).length !== 0
    },
    hasFacetChanges () {
      return this.$store.getters[`searchStore/searchModules/${this.moduleId}/hasFacetChanges`]
    },
    hasPublications () {
      return this.publications.length !== 0
    },
    shareUrl () {
      const queryObject = this.$store.getters[`searchStore/searchModules/${this.moduleId}/getUrlQueryObject`]
      const route = this.$router.resolve({
        name: 'results',
        query: {
          query: JSON.stringify(queryObject)
        }
      })
      const fullUrl = window.location.origin + route.fullPath
      return fullUrl
    },
    showCheckboxes () {
      return this.$store.getters.userHasUserRole
    }
  },
  data: () => ({
    LOADING_STATES: STATES,
    scrollNavigationTriggerOffset: 300,
    checkedResults: []
  }),
  methods: {
    loadNextResults () {
      this.$store.dispatch(`searchStore/searchModules/${this.moduleId}/nextPage`)
    },
    onFacetToggle (facetState) {
      this.$store.dispatch(`searchStore/searchModules/${this.moduleId}/setFacetChangeState`, facetState)
    },
    resetActiveFacets () {
      this.$store.dispatch(`searchStore/searchModules/${this.moduleId}/resetFacetChanges`)
      this.$store.dispatch(`searchStore/searchModules/${this.moduleId}/resetActiveFacets`)
      this.$store.dispatch(`searchStore/searchModules/${this.moduleId}/search`, false)
    },
    applyFacetChanges () {
      this.$store.dispatch(`searchStore/searchModules/${this.moduleId}/applyFacetChanges`)
      this.$store.dispatch(`searchStore/searchModules/${this.moduleId}/resetFacetChanges`)
      this.$store.dispatch(`searchStore/searchModules/${this.moduleId}/search`, false)
    },
    resetFacetChanges () {
      this.$store.dispatch(`searchStore/searchModules/${this.moduleId}/resetFacetChanges`)
    },
    onPublicationClick (publicationId) {
      this.$emit('select', {
        publication: publicationId,
        module: this.moduleId
      })
    },
    onResultlistScroll (e) {
      const listElement = e.target
      const scrollBottomPosition = listElement.scrollTop + listElement.getBoundingClientRect().height
      const totalResultHeight = this.$refs.resultlist.getBoundingClientRect().height

      if (totalResultHeight - scrollBottomPosition < this.scrollNavigationTriggerOffset) {
        if (this.loadingState === STATES.READY && !this.allPagesLoaded) this.loadNextResults()
      }
    },
    addQueryToBookshelf () {
      const queryObject = this.$store.getters[`searchStore/searchModules/${this.moduleId}/getUrlQueryObject`]
      const copyQueryObject = JSON.parse(JSON.stringify(queryObject))

      copyQueryObject.connection = parseInt(copyQueryObject.connection)

      const addData = {
        type: ITEM_TYPES.QUERY,
        data: copyQueryObject
      }

      Events.$emit('openModalBookshelfItemAdd', addData)
    },
    addCheckedResultsToBookshelf () {
      if (!this.checkedResults.length) return

      const addData = this.checkedResults.map(publicationId => ({
        type: ITEM_TYPES.PUBLICATION,
        data: {
          publicationId: publicationId,
          connectionId: parseInt(this.moduleId)
        }
      }))

      Events.$emit('openModalBookshelfItemAdd', addData)
    },
    uncheckResults () {
      this.checkedResults = []
    }
  },
  components: {
    'module-facets': ModuleFacets,
    'module-result': ModuleResult,
    'copy-clipboard': CopyClipboard
  },
  mounted () {
    Events.$on('uncheckResults', this.uncheckResults)
  },
  beforeUnmount () {
    Events.$off('uncheckResults', this.uncheckResults)
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/css/_constants";

  .module-result-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .module-result-container {
    height: 100%;
    display: flex;
    direction: row;
  }

  .module-result-sidebar {
    height: 100%;
    width: $sidebar_width;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 0;
    box-shadow: $sidebar_shadow;
  }

  .module-result-options {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    flex-grow: 0;
    align-items: center;
    padding: 0 10px;
    border-bottom: 1px solid #ddd;

    .module-result-option-spacer {
      flex-grow: 1;
    }

    .module-result-option {
      display: inline-block;
      height: 40px;
      padding: 10px;
      flex-shrink: 0;
      cursor: pointer;

      &-button {
        &:hover {
          border: 1px solid black;
        }
      }

      &-image {
        margin: 0;
      }

      &-foldout {
        display: none;
        background-color: white;
        padding: 10px;
        position: absolute;
        left: 0;
        top: calc(100% - 10px);
        border-radius: 4px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
        z-index: 10;
      }

      &:hover .module-result-option-foldout {
        display: block;
        cursor: default;
      }
    }
  }

  .module-result-facets {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;
  }

  .facet-loading-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0.6;
  }

  .result-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .result-resultlist-container {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    flex-grow: 1;

    .actions {
      padding: 10px;
    }
  }

  .result-resultlist {
    display: flex;
    flex-direction: column;

    &.result-resultlist-tiles {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .result {
    cursor: pointer;

    &.result-selected {
      padding-left: 16px;
      border-left: 4px solid $main_light;
    }

    &:not(.result-selected):hover {
      padding-left: 16px;
      border-left: 4px solid $grey_dark;
    }

    &.result-tile {
      width: 33%;
    }
  }

  @media screen and (max-width: 1500px) {
    .result.result-tile {
      width: 50%;
    }
  }

  .module-result-share {
    width: 300px;
  }

  .module-result-empty {
    padding: 10px;
  }

  .result-selectedactions-container {
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
  }
</style>
