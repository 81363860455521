<template>
  <div class="short">
    <div class="short-cover">
      <publication-cover :url="publication.cover" ref="cover"></publication-cover>
    </div>
    <div class="short-metadata">
      <div v-if="publication.authors?.length" class="short-metadata-field metadata-authors">{{ publication.authors.join('; ') }}</div>
      <div v-if="publication.title" class="short-metadata-field metadata-title">{{ publication.title }}</div>
      <div v-if="publication.subtitle" class="short-metadata-field metadata-subtitle">{{ publication.subtitle }}</div>
      <div v-if="publishingDisplay" class="short-metadata-field metadata-publication">{{ publishingDisplay }}</div>
      <div class="short-metadata-field metadata-actions">
        <router-link
          v-if="publication.pubkey && publication.licenseavailable"
          :to="{ name: 'publicationloader', params: { pubKey: publication.pubkey } }"
          class="input-button input-button-outlined"
        >{{ capitalize($t('read')) }}</router-link>
        <button
          v-else-if="publication.pubkey && !publication.licenseavailable"
          class="input-button input-button-outlined"
          disabled
        >{{ $t('in use') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import PublicationCover from '@/components/publication/PublicationCover'

export default {
  name: 'module-aggregate-publication',
  props: ['publication'],
  computed: {
    publishingDisplay () {
      const fields = []

      if (this.publication.publisher) fields.push(this.publication.publisher)
      if (this.publication.yearofpublication) fields.push(this.publication.yearofpublication)
      if (this.publication.edition) fields.push(`(${this.publication.edition})`)

      return fields.join(' ')
    }
  },
  components: {
    'publication-cover': PublicationCover
  },
  mounted () {
    if (this.$refs.cover?.loadImage) this.$refs.cover.loadImage()
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/css/_constants";

  .short {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    padding: 10px;
    cursor: pointer;

    &-cover {
      width: 100px;
      height: 150px;
    }

    &-metadata {
      padding-left: 20px;
      width: 600px;

      &-field {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .metadata {
    &-authors {
      font-style: italic;
      margin-bottom: 4px;
    }

    &-title {
      font-size: $font_size + 2;
      font-weight: bold;
    }

    &-publication {
      margin-top: 10px;
    }

    &-actions {
      margin-top: 10px;
    }
  }
</style>
