<template>
  <transition name="book-catalogue-panel-transition">
    <div class="panel book-catalogue-detail-container" v-if="book">
      <div class="panel-header">
        <div class="panel-searchresult">{{ $t('Search Result') }}</div>
        <button class="panel-close input-button detail-closebtn" @click="$emit('close')">
          <img src="/img/icons/close.png" width="20" height="20">
        </button>
      </div>
      <div class="panel-content">
        <div class="panel-content-section">
          <div class="book-catalogue-detail-title-container">
              <div v-if="book.title" v-html="parseDOM(book.title)" class="book-catalogue-detail-title-container-title"></div>
              <div v-if="book.subtitle" class="book-catalogue-detail-title-container-subtitle">{{book.subtitle}}</div>
          </div>
          <div class="book-catalogue-detail-cover-container">
            <LazyLoadingImg ref="lazyLoadingCover" class="book-catalogue-detail-cover" :url="getCoverUrlForBook(book)">
              <template v-slot:status-failed>
                <img src="/img/cover_placeholder.png">
              </template>
            </LazyLoadingImg>
          </div>
          <div class="book-catalogue-detail-metadata-container">
            <table style="border-collapse: collapse;">
              <tr v-if="book.authors && book.authors.length && book.authors[0] !== ''" class="book-catalogue-detail-authors">
                <td>{{ book.authors.length > 1 ? $t('Authors') : $t('Author') }}</td>
                <td>
                  <div v-for="(author, index) in book.authors" :key="author" class="book-catalogue-detail-authors-author">{{ author }}{{ (index === book.authors.length - 1) ? '' : ';' }}</div>
                </td>
              </tr>
              <tr v-if="book.isbn">
                <td>{{ $t('ISBN') }}</td>
                <td>{{ book.isbn }}</td>
              </tr>
              <tr v-if="yearOfPublication">
                <td>{{ $t('Year') }}</td>
                <td>{{ yearOfPublication }}</td>
              </tr>
              <tr v-if="book.publisher">
                <td>{{ $t('Publisher') }}</td>
                <td>{{ book.publisher }}</td>
              </tr>
              <tr v-if="book.edition">
                <td>{{ $t('Edition') }}</td>
                <td>{{ book.edition }}</td>
              </tr>
              <tr v-if="book.jurisdiction">
                <td>{{ $t('Jurisdiction') }}</td>
                <td>
                  <div v-for="(jurisdiction, index) in jurisdictionList" :key="jurisdiction">{{ jurisdiction }}{{ (index === jurisdictionList.length - 1) ? '' : ',' }}</div>
                </td>
              </tr>
            </table>
          </div>
          <div class="clear"></div>
        </div>

        <div class="panel-content-section">
          <div class="book-catalogue-detail-actions-container">
            <slot name="actions"></slot>
          </div>
        </div>

        <div v-if="book.abstract" class="panel-content-section">
          <ExpandablePanelSection :defaultOpen="true">
            <template v-slot:title>
              {{ $t('Description') }}
            </template>
            <template v-slot:content>
              <div v-html="parseDOM(book.abstract)"></div>
            </template>
          </ExpandablePanelSection>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import LazyLoadingImg from '@/components/LazyLoadingImg.vue'
import ExpandablePanelSection from '@/components/ExpandablePanelSection.vue'
import bookCoverUrlMixin from '@/mixins/bookCoverUrl'
import domparser from '@/mixins/domparser'

export default {
  props: {
    book: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    yearOfPublication () {
      return this.book.yearOfPublication
    },
    jurisdictionList () {
      let jurisdictionSplit = []

      if (this.book && this.book.jurisdiction) {
        jurisdictionSplit = this.book.jurisdiction.split('|')
      }

      return jurisdictionSplit
    }
  },
  watch: {
    book (newBook) {
      if (newBook) {
        this.$nextTick(() => {
          this.$refs.lazyLoadingCover.loadImage()
        })
      }
    }
  },
  mounted () {
    if (this.book) {
      this.$refs.lazyLoadingCover.loadImage()
    }
  },
  components: {
    LazyLoadingImg,
    ExpandablePanelSection
  },
  mixins: [
    bookCoverUrlMixin,
    domparser
  ]
}
</script>

<style>
</style>
