<template>
  <div class="acquisitions">
    <div class="acquisitions-header">{{ capitalize($t('new acquisitions')) }}</div>
    <div class="acquisitions-content">
      <div v-if="acquisitionsLoadingState === LOADING_STATES.READY && acquisitions.length" class="acquisitions-list">
        <button @click="previousPage" class="acquisitions-navigation navigation-previous" :disabled="page <= 0"></button>
        <acquisition
          v-for="(acquisition, index) of acquisitions"
          :key="acquisition.id"
          v-show="index >= page * pageSize && index < (page * pageSize) + pageSize"
          :acquisition="acquisition"
          ref="acquisition"
          @click="onSelectResult(acquisition.id, acquisition.sourcemodule)"
        ></acquisition>
        <button @click="nextPage" class="acquisitions-navigation navigation-next" :disabled="page >= maxPage"></button>
      </div>
      <div v-if="acquisitionsLoadingState === LOADING_STATES.LOADING" class="acquisitions-loading">
        <spinner :size="40" :width="4"></spinner>
      </div>
      <div v-if="acquisitionsLoadingState === LOADING_STATES.ERROR" class="acquisitions-error">
        {{ capitalize($t('there was a problem loading the acquisitions')) }}
      </div>
      <div v-if="acquisitionsLoadingState === LOADING_STATES.READY && !acquisitions.length" class="acquisitions-empty">
        {{ capitalize($t('there are no new acquisitions')) }}
      </div>
    </div>
  </div>
</template>

<script>
import Acquisition from '@/components/acquisitions/Acquisition'
import Spinner from '@/components/utilities/Spinner'
import { mapState } from 'vuex'
import STATES from '@/constants/states'

export default {
  name: 'acquisitions',
  emits: ['select'],
  computed: {
    ...mapState('searchStore', ['acquisitionsLoadingState', 'acquisitions']),
    maxPage () {
      return Math.floor((this.acquisitions.length - 1) / this.pageSize)
    }
  },
  data: () => ({
    LOADING_STATES: STATES,
    page: 0,
    pageSize: 10
  }),
  methods: {
    async fetchAcquisitions () {
      await this.$store.dispatch('searchStore/fetchAcquisitions')
      this.setPage(0)
    },
    loadPageCovers (page) {
      const acquisitionComponents = this.$refs.acquisition
      if (!acquisitionComponents) return

      for (let i = page * this.pageSize; i < (page * this.pageSize) + this.pageSize; i++) {
        if (i >= acquisitionComponents.length) break

        const acquisitionComponent = acquisitionComponents[i]
        if (acquisitionComponent.load) acquisitionComponent.load()
      }
    },
    setPage (page) {
      if (page > this.maxPage) page = this.maxPage
      if (page < 0) page = 0

      this.page = page
      this.loadPageCovers(page)
    },
    nextPage () {
      if (this.page >= this.maxPage) return
      this.setPage(this.page + 1)
    },
    previousPage () {
      if (this.page <= 0) return
      this.setPage(this.page - 1)
    },
    onSelectResult (resultId, moduleId) {
      this.$emit('select', {
        publication: resultId,
        module: moduleId
      })
    }
  },
  components: {
    acquisition: Acquisition,
    spinner: Spinner
  },
  mounted () {
    if (this.acquisitionsLoadingState === STATES.INITIAL) {
      this.fetchAcquisitions()
    } else if (this.acquisitionsLoadingState === STATES.READY && this.acquisitions.length) {
      this.setPage(0)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/css/_constants";

  .acquisitions {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &-header {
      font-size: $font_size + 2;
      font-weight: bold;
      margin-bottom: 10px;
    }

    &-content {
      height: 150px;
    }

    &-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
    }

    &-navigation {
      width: 28px;
      height: 28px;
      border: 1px solid $grey_middle;
      border-radius: 50%;
      background: white;
      cursor: pointer;

      &:disabled {
        visibility: hidden;
      }

      &:hover {
        border-color: $main_light;

        &::after {
          border-right-color: $main_light;
        }
      }

      &::after {
        content: "";
        position: absolute;
        left: calc(50% - 5px);
        top: calc(50% - 6px);
        border-right: 8px solid #ccc;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
      }

      &.navigation-next {
        &::after {
          left: calc(50% - 3px);
          border-right: none;
          border-left: 8px solid #ccc;
        }

        &:hover::after {
          border-left-color: $main_light;
        }
      }
    }

    &-loading, &-empty, &-error {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    &-error {
      color: $red;
    }
  }
</style>
