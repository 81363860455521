export default {
  "common.excluding-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ohne MwSt."])},
  "header": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument schließen"])},
    "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwaltung"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])}
  },
  "Log in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "Try again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholungsversuch"])},
  "Version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
  "Loading reader...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reader laden..."])},
  "Loading publication...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publikation laden..."])},
  "Loan period expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leihfrist abgelaufen"])},
  "The loan period for this document has expired. Choose \"request extension\" to request an extension, or \"close\" to close this document.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Leihfrist für dieses Dokument ist abgelaufen. Wählen Sie \"Verlängerung beantragen\", um eine Verlängerung der Leihfrist zu beantragen, oder \"schließen\" Sie das Dokument."])},
  "The loan period is about to expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Leihfrist läuft demnächst ab"])},
  "The loan period is about to expire due to inactivity. Do you want to continue using this document or do you want to close it?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Leihfrist läuft aufgrund von Inaktivität ab. Möchten Sie dieses Dokument weiter lesen oder schließen?"])},
  "Continue using this document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortfahren mit diesem Dokument"])},
  "Request extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlängerung beantragen"])},
  "Close document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument schließen"])},
  "Read online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online lesen"])},
  "In use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Gebrauch"])},
  "Filter results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnisse filtern"])},
  "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr"])},
  "Less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weniger"])},
  "keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stichwörter"])},
  "authors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoren"])},
  "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlag"])},
  "jurisdiction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtsgebiete"])},
  "yearofpublication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erscheinungsjahr"])},
  "Authors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoren"])},
  "Author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
  "ISBN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
  "Publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlag"])},
  "Edition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgabe"])},
  "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])},
  "Jurisdiction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtsgebiet"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "Text size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textgröße"])},
  "Zoom in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergrößern"])},
  "Zoom out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduzieren"])},
  "Automatic zoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Größe"])},
  "Navigate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigieren"])},
  "Navigate to the first page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigieren Sie zur ersten Seite"])},
  "Navigate to the last page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigieren Sie zur letzten Seite"])},
  "Navigate to the previous chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigieren zum vorherigen Kapitel"])},
  "Navigate to the next chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum nächsten Kapitel navigieren"])},
  "Navigate to the previous page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigieren Sie zur vorherigen Seite"])},
  "Navigate to the next page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur nächsten Seite navigieren"])},
  "Searching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf der Suche"])},
  "Search in this document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Dokument durchsuchen"])},
  "Current query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Suchabfrage"])},
  "There are no results for this query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ergebnisse für diese Suchanfrage gefunden"])},
  "Stop search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche beenden"])},
  "Use the searchbar to start searching in this document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden Sie die Suchleiste, um dieses Dokument zu durchsuchen"])},
  "Search for title, author or text in a document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach Titel, Autor oder Text in einem Dokument"])},
  "Search Result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchergebnis"])},
  "result found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnis gefunden"])},
  "results found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnisse gefunden"])},
  "Print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drucken"])},
  "Print from the current page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drucken von der aktuellen Seite"])},
  "Amount of pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Seiten"])},
  "Preparing print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorbereitung des Druckvorgangs"])},
  "Search in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche in"])},
  "Metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata"])},
  "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "Both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beide"])},
  "Search for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach"])},
  "searchbar.for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nach"])},
  "Seperate words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lose Wörter"])},
  "Exact sentence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exakter Satz"])},
  "License key expired/not known": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenzschlüssel ist abgelaufen/unbekannt"])},
  "Server could not be reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server kann nicht erreicht werden"])},
  "There was a problem establishing a connection to the server. Please try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Verbindung zum Server ist ein Problem aufgetreten. Bitte versuchen Sie es erneut."])},
  "Could not get page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann die Seite nicht finden"])},
  "Looks like the page you are trying to load doesn't exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die von Ihnen angeforderte Seite existiert nicht"])},
  "No appearances found for this publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Kopien für diese Ausgabe gefunden"])},
  "There are no appearances linked to this publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Kopien mit dieser Ausgabe verknüpft."])},
  "Multiple appearances found for this publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrere Exemplare für diese Ausgabe gefunden"])},
  "There are multiple appearances found for this publication.<br>Please choose which appearance you would like to read:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für diese Ausgabe wurden mehrere Exemplare gefunden.<br>Wählen Sie aus, welches Exemplar Sie gerne lesen möchten:"])},
  "Publication not be found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgabe nicht gefunden"])},
  "Document not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document nicht gefunden"])},
  "The requested publication could not be found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die gewünschte Ausgabe kann nicht gefunden werden"])},
  "Metadata not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata nicht gefunden"])},
  "The metadata could not be found for this document. This data is needed to initialize the reader.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für diese Ausgabe wurden keine Metadaten gefunden. Diese Daten sind erforderlich, um den Reader zu starten."])},
  "This document could not be opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Dokument kann nicht geöffnet werden"])},
  "This document is already in use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Dokument ist bereits in Gebrauch"])},
  "This document is already in use by a colleague. Try again later.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Dokument wird derzeit bereits von einem Kollegen verwendet. Bitte versuchen Sie es später noch einmal."])},
  "This document is currently opened by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Dokument wird derzeit verwendet von"])},
  "Please try again when one of the open documents is closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versuchen Sie es später noch einmal, wenn ein Dokument verfügbar ist"])},
  "Could not get the page for printing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Seite konnte nicht zum Drucken abgerufen werden"])},
  "Looks like the page you are trying to print doesn't exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es scheint, dass die Seite, die Sie zu drucken versuchen, nicht existiert"])},
  "Authentication could not be refreshed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierung konnte nicht erneuert werden"])},
  "The authentication with the server has expired and could not be refreshed. Please log in again to continue using the application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Authentifizierung beim Server ist abgelaufen und konnte nicht erneuert werden. Bitte melden Sie sich erneut an, um mit der Anwendung fortzufahren."])},
  "An error occurred in our server while searching in the catalogue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Suche im Katalog ist ein Problem auf unserem Server aufgetreten"])},
  "Please try again later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versuchen Sie es später noch einmal"])},
  "Could not switch client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann die Umgebung nicht ändern"])},
  "Something went wrong while switching client, please try again later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Umgebungswechsel ist etwas schief gelaufen, versuchen Sie es später noch einmal."])},
  "Something went wrong while sending the request, pleasy try again later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Absenden der Bewerbung ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal."])},
  "You will automatically be redirected for authentication.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Seite wird automatisch zur Authentifizierung weitergeleitet."])},
  "Click here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik hier"])},
  "to navigate to the authentication page if automatically redirecting is taking a long time.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["um manuell zur Authentifizierungsseite zu navigieren, wenn die automatische Weiterleitung zu lange dauert."])},
  "A problem occurred while authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Authentifizierung ist ein Problem aufgetreten"])},
  "Token missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlender Schlüssel"])},
  "Please try again later or contact an administrator if the problem keeps occurring.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versuchen Sie es später erneut oder wenden Sie sich an einen Administrator, wenn das Problem weiterhin besteht."])},
  "varReaderSearchResultsPages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Es gibt ", _interpolate(_named("maxIndex")), " Seiten, auf denen Ergebnisse gefunden wurden"])},
  "varReaderSearchResults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Seite ", _interpolate(_named("currentIndex")), " von ", _interpolate(_named("maxIndex"))])},
  "Shopping basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warenkorb"])},
  "There are no items in your shopping basket.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Ihrem Warenkorb befindet sich nichts."])},
  "Checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellen"])},
  "Back to shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zum Shop"])},
  "item.new.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Diese Ausgabe wurde vor weniger als ", _interpolate(_named("daysMarkedAsNew")), " Tagen hinzugefügt."])},
  "shop": {
    "library": {
      "search": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach Titel oder ISBN"])}
      },
      "items": {
        "add-to-basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Warenkorb"])},
        "already-in-basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereits im Warenkorb"])},
        "already-in-collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereits in Ihrer Sammlung"])}
      }
    },
    "basket": {
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
    },
    "checkout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellen"])},
      "complete-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständige Bestellung"])},
      "order-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bestellung ist fehlgeschlagen. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut."])},
      "validation": {
        "accept-terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte akzeptieren Sie alle Bedingungen und Konditionen"])}
      },
      "privacy-agreements": {
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ich habe die ", _interpolate(_list(0)), " gelesen und akzeptiere sie."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzbestimmungen"])}
      },
      "general-agreements": {
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ich habe die ", _interpolate(_list(0)), " gelesen und akzeptiere sie."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedingungen und Konditionen"])}
      }
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung erfolgreich gesendet"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihre Bestellung! Sie erhalten so bald wie möglich eine Empfangsbestätigung per E-Mail und wir werden Sie erneut kontaktieren, wenn die bestellten Bücher in Ihrem XPOSI verfügbar sind."])}
    }
  },
  "searchOption": {
    "searchMode": {
      "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einfach"])},
      "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erweitert"])}
    },
    "searchContext": {
      "metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhalt"])},
      "metadataAndContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata und Inhalt"])}
    },
    "fieldRelation": {
      "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und"])},
      "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder"])}
    },
    "fieldMode": {
      "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bevat"])},
      "exact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exakt"])},
      "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nicht"])}
    }
  },
  "sorting": {
    "order": {
      "descending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["absteigend (Z-A)"])},
      "ascending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aufsteigend (A-Z)"])}
    },
    "fields": {
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevanz"])}
    }
  },
  "metadataField": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
    "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
    "authors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoren"])},
    "corporateauthor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensautor"])},
    "corporateauthors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensaut."])},
    "yearofpublication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erscheinungsjahr"])},
    "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
    "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlag"])},
    "documenttype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentart"])},
    "seriestitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie"])},
    "seriesnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seriennummer"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenz"])},
    "placeofpublication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erscheinungsort"])},
    "edition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgabe"])},
    "annotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmerkungen"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
    "filenumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktenzeichen"])},
    "filedatestart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum"])},
    "filedateend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum"])},
    "filedatedestroyed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vernichtungsdatum"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubrik"])},
    "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassifizierung"])},
    "keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stichwörter"])},
    "geographicalkeywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geografische Stichwörter"])},
    "registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stammummer"])},
    "collation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollation"])},
    "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothek"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
    "loanstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausleihstatus"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])},
    "part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teil"])},
    "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemplar"])},
    "abstract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signatur"])},
    "bibiscode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIBIScode"])},
    "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument"])},
    "collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollektion"])},
    "additionalinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zusätzliche Informationen"])},
    "parenttitles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["übergeordnete Titel"])},
    "childtitles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["untergeordnete Titels"])},
    "rubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubrik"])},
    "nuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuance"])},
    "extranuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extra Nuance"])}
  },
  "facets": {
    "sorttype": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sortiert nach ", _interpolate(_named("currentType")), ", klicken Sie zum Sortieren nach ", _interpolate(_named("alternativeType"))])},
    "sortorder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["sortiert nach ", _interpolate(_named("currentOrder")), " Reihenfolge, klicken Sie hier, um nach ", _interpolate(_named("alternativeOrder")), " Reihenfolge zu sortieren"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert"])},
    "hits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl"])},
    "ascending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aufsteigende"])},
    "descending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["absteigende"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter anwenden"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter löschen"])},
    "cancelChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filteränderungen abbrechen"])},
    "freeFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["benutzerdefinierte Filter"])}
  },
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])},
  "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erweiterte"])},
  "sort by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sortieren nach"])},
  "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["innerhalb"])},
  "add filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter zufügen"])},
  "remove filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter entfernen"])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnisse"])},
  "all results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alle Ergebnisse"])},
  "show all {modulename} results": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["alle ", _interpolate(_named("modulname")), " Ergebnisse"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["löschen"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["schließen"])},
  "load additional results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weitere Ergebnisse laden"])},
  "loading additional results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mehr Ergebnisse laden"])},
  "all results have been loaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alle Ergebnisse wurden geladen"])},
  "there are no results for this query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keine Ergebnisse für diese Suchanfrage gefunden"])},
  "there has been an error while searching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Suche ist ein Problem aufgetreten"])},
  "searching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["auf der Suche"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mehr"])},
  "less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weniger"])},
  "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lesen"])},
  "in use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["belegt"])},
  "characteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenndaten"])},
  "abstract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung"])},
  "multimedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multimedia"])},
  "publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgabe"])},
  "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiv"])},
  "other characteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sonstige Kenndaten"])},
  "title relations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titelbeziehungen"])},
  "copies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemplare"])},
  "moreCopyDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemplaredokument(en)"])},
  "loanable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verleihbar"])},
  "not loanable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht verleihbar"])},
  "loaned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geliehen"])},
  "new acquisitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuaufnahmen"])},
  "there was a problem loading the acquisitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akquisitionen können nicht geladen werden"])},
  "there are no new acquisitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt keine Neuerwerbungen"])},
  "There are filled advanced fields. Click to open the advanced search panel.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt ausgefüllte Felder für die erweiterte Suche. Klicken Sie, um das Feld für die erweiterte Suche zu öffnen."])},
  "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfordern"])},
  "email_request": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Adresse"])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abteilung"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht:"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["an die Bibliothek senden"])},
    "is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist obligatorisch"])},
    "is_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist ungültig"])}
  },
  "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umgebung"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
  "languages": {
    "nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlands"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])}
  },
  "placeholders": {
    "rich_text_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie hier einen Text ein..."])}
  },
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["speichern"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stornieren"])},
  "select_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildauswahl"])},
  "error_image_wrong_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dieser Dateityp wird nicht unterstützt, versuchen Sie einen der folgenden Dateitypen: ", _interpolate(_named("exampleFileTypes"))])},
  "error_image_no_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Datei ausgewählt"])},
  "admin": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwaltung"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeines"])},
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
    "changeLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logowechsel"])},
    "deleteLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktuelles Logo entfernen"])},
    "informationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationstexte"])},
    "showInformationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationstexte anzeigen"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suchen"])},
    "searchResultList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ergebnisliste"])},
    "showCopyDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exemplaredokumenten in der Ergebnisliste anzeigen"])},
    "defaultLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["standardsprache"])},
    "searchDefaults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["standardsucheinstellungen"])},
    "searchContext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontext"])},
    "resultSorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortierung"])},
    "advancedSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erweiterte Suche"])},
    "showAdvancedSearchOnHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erweitertes Suchfeld auf dem Startbildschirm öffnen"])},
    "defaultAdvancedSearchFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["standardmäßig geöffnete Suchfelder"])}
  },
  "valueNotSupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dieser Wert wird nicht (mehr) unterstützt"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teilen"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kopieren"])},
  "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionen"])},
  "resultlistmode": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ergebnisansicht"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste"])},
    "tiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kachels"])}
  },
  "actions": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hinzufügen"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["löschen"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bearbeiten"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stornieren"])},
    "moveUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nach oben verschieben"])},
    "moveDown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nach unten verschieben"])}
  },
  "bookshelf": {
    "bookshelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bücherregal"])},
    "bookshelves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bücherregale"])},
    "myBookshelves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meine Bücherregale"])},
    "externalBookshelves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["externe Bücherregale"])},
    "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es gibt noch keine Bücherregale"])},
    "addBookshelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bücherregal hinzufügen"])},
    "editBookshelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bücherregal bearbeiten"])},
    "deleteBookshelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bücherregal löschen"])},
    "bookshelfName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Bücherregals"])},
    "is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist erforderlich"])},
    "bookshelfLoadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["das angeforderte Bücherregal kann nicht geladen werden"])},
    "deleteBookshelfConfirmation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["sind Sie sicher, dass Sie [ ", _interpolate(_named("bookshelfName")), " ] löschen möchten?"])},
    "addToBookshelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zum Bücherregal hinzufügen"])},
    "emptyContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dieses Bücherregal ist leer"])},
    "addItemTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element hinzufügen zu"])},
    "newBookshelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neues Bücherregal"])},
    "existingBookshelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vorhandenes Bücherregal"])}
  },
  "selectedResult": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " ausgewähltes Ergebnis"])},
  "selectedResults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " ausgewählte Ergebnisse"])}
}