<template>
  <div class="inline-loading-indicator">
    <span class="material-icons-outlined">autorenew</span>
  </div>
</template>

<script>
export default {
}
</script>

<style>
</style>
