<template>
  <div class="moduletabs">
    <module-tabbar-all-tab
      v-if='modules.length > 1'
      :isActive="activeTab === 'all'"
      @select="onTabSelect('all')"
    ></module-tabbar-all-tab>
    <module-tabbar-tab
      v-for="module of modules"
      :key="module.id"
      :moduleId="module.id"
      :isActive="activeTab === module.id"
      @select="onTabSelect(module.id)"
    >
      {{ module.name }}
    </module-tabbar-tab>
  </div>
</template>

<script>
import ModuleTabbarAllTab from './ModuleTabbarAllTab'
import ModuleTabbarTab from './ModuleTabbarTab'

export default {
  name: 'module-tabbar',
  props: ['activeTab', 'modules'],
  emits: ['update:activeTab'],
  methods: {
    onTabSelect (tabId) {
      this.$emit('update:activeTab', tabId)
    }
  },
  components: {
    'module-tabbar-all-tab': ModuleTabbarAllTab,
    'module-tabbar-tab': ModuleTabbarTab
  }
}
</script>

<style lang="scss" scoped>
  .moduletabs {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
</style>
